import { createWebHistory, createRouter } from "vue-router";

import Welcome from "@/components/Welcome.vue";
import Login from "@/components/AuthComponent/Login.vue";
import Register from "@/components/AuthComponent/Register.vue";
import ResetPassword from "@/components/AuthComponent/ResetPassword.vue";
import Choice from "@/components/OwnerComponent/ProductComponent/SubProductComponent/Choice.vue";
import Index from "@/components/OwnerComponent/ProductComponent/SubProductComponent/Index.vue";
// lazy-loaded

// Resto Component
const Resto = () => import("@/components/RestoComponent/Resto.vue");
const RestoDetail = () => import("@/components/RestoComponent/RestoDetail.vue");
const RestoFeature = () =>
  import("@/components/RestoComponent/RestoFeature.vue");
const RestoSearch = () => import("@/components/RestoComponent/RestoSearch.vue");
const Profile = () => import("@/components/AuthComponent/Profile.vue");
const UpdateProfile = () =>
  import("@/components/AuthComponent/EditProfile.vue");
const NguponYuk = () =>
  import("@/components/NguponYukComponent/NguponYuk.vue");
const NguponYukHistory = () =>
  import("@/components/NguponYukComponent/NguponYukHistory.vue");
const ReferralNguponYuk = () =>
  import("@/components/NguponYukComponent/ReferralNguponYuk.vue");
const UserReferralNguponYuk = () =>
  import("@/components/NguponYukComponent/UserReferralNguponYuk.vue");
const NguponYukCart = () =>
  import("@/components/NguponYukComponent/NguponYukCart.vue");
const NguponYukOutletList = () =>
  import("@/components/NguponYukComponent/NguponYukOutletList.vue");
const Checkout = () => import("@/components/CheckoutComponent/Checkout.vue");
const Transaction = () =>
  import("@/components/TransactionComponent/Transaction.vue");
const Transactions = () =>
  import("@/components/TransactionComponent/Transactions.vue");
const VoucherTransaction = () =>
  import("@/components/TransactionComponent/Voucher.vue");
const CouponTransaction = () =>
  import("@/components/TransactionComponent/NguponYuk.vue");
const Map = () => import("@/components/MapComponent/Map.vue");
const QR = () => import("@/components/QrComponent/QR.vue");
const QRHome = () => import("@/components/QrComponent/QRHome.vue");
const History = () => import("@/components/HistoryComponent/History.vue");
const HistoryDetail = () =>
  import("@/components/HistoryComponent/HistoryDetail.vue");
const Chat = () => import("@/components/Chat.vue");
const Group = () => import("@/components/GroupComponent/Group.vue");

//Owner Resto Component
const OwnerChat = () => import("@/components/OwnerComponent/Chat.vue");
const OwnerTable = () =>
  import("@/components/OwnerComponent/TableComponent/Table.vue");
const OwnerRestoCreate = () =>
  import("@/components/OwnerComponent/RestoComponent/RestoCreate.vue");
const OwnerRestoEdit = () =>
  import("@/components/OwnerComponent/RestoComponent/RestoEdit.vue");
const OwnerReferral = () =>
  import("@/components/OwnerComponent/RestoComponent/ReferralCode.vue");
const OwnerVerifikasi = () =>
  import("@/components/OwnerComponent/RestoComponent/RestoVerifikasi.vue");
const OwnerHome = () => import("@/components/OwnerComponent/Home.vue");
const OwnerProduct = () =>
  import("@/components/OwnerComponent/ProductComponent/Product.vue");
const OwnerProductForm = () =>
  import("@/components/OwnerComponent/ProductComponent/ProductForm.vue");
const OwnerSubCategories = () =>
  import(
    "@/components/OwnerComponent/SubCategoriesComponent/SubCategories.vue"
  );
const OwnerPromo = () =>
  import("@/components/OwnerComponent/PromoComponent/Promo.vue");
const OwnerPromoForm = () =>
  import("@/components/OwnerComponent/PromoComponent/PromoForm.vue");
const OwnerTransaction = () =>
  import("@/components/OwnerComponent/TransactionComponent/Transaction.vue");
const OwnerDetailTransaction = () =>
  import(
    "@/components/OwnerComponent/TransactionComponent/DetailTransaction.vue"
  );
const OwnerFollower = () =>
  import("@/components/OwnerComponent/FollowerComponent/Follower.vue");
const OwnerVoucher = () =>
  import("@/components/OwnerComponent/VoucherComponent/Voucher.vue");
const OwnerUser = () =>
  import("@/components/OwnerComponent/UserComponent/User.vue");
const OwnerSchedule = () =>
  import("@/components/OwnerComponent/ScheduleComponent/Schedule.vue");
const OwnerSubscription = () =>
  import("@/components/OwnerComponent/SubscriptionComponent/Subscription.vue");
const OwnerHistory = () =>
  import("@/components/OwnerComponent/HistoryComponent/History.vue");
const OwnerOwners = () =>
  import("@/components/OwnerComponent/OwnerComponent/Owner.vue");
const TestNotification = () =>
  import("@/components/OwnerComponent/OwnerComponent/Notification.vue");
const OwnerIncome = () =>
  import("@/components/OwnerComponent/IncomeComponent/Income.vue");
const OwnerRestoDetail = () =>
  import("@/components/OwnerComponent/RestoComponent/Resto.vue");
const Error = () =>
  import("@/components/ErrorComponent/Error.vue");

const Introductions = () => import("@/components/IntroductionComponent/Introduction.vue");

let routes = [
  {
    path: "/error",
    component: Error,
  },
  {
    path: "/",
    component: Welcome,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/resto",
    name: "Resto",
    // lazy-loaded
    component: Resto,
  },
  {
    path: "/group/:groupId",
    name: "Group",
    // lazy-loaded
    component: Group,
  },
  {
    path: "/resto-detail/:restoId",
    name: "RestoDetail",
    // lazy-loaded
    component: RestoDetail,
    props: true,
  },
  {
    path: "/resto-feature/:page",
    name: "RestoFeature",
    // lazy-loaded
    component: RestoFeature,
    props: true,
  },
  {
    path: "/resto-search",
    name: "RestoSearch",
    // lazy-loaded
    component: RestoSearch,
  },
  {
    path: "/checkout",
    name: "Checkout",
    // lazy-loaded
    component: Checkout,
  },
  {
    path: "/transaction/:from",
    name: "Transaction",
    // lazy-loaded
    component: Transaction,
  },
  {
    path: "/transaction-voucher/:outlet",
    name: "VoucherTransaction",
    component: VoucherTransaction,
  },
  {
    path: "/transaction/ngupon-yuk",
    name: "CouponTransaction",
    component: CouponTransaction,
  },
  {
    path: "/transactions",
    name: "Transactions",
    // lazy-loaded
    component: Transactions,
  },
  {
    path: "/map",
    name: "Map",
    // lazy-loaded
    component: Map,
  },
  {
    path: "/barcode/:detail",
    name: "Qr",
    // lazy-loaded
    component: QR,
  },
  {
    path: "/scan",
    name: "QrScanner",
    // lazy-loaded
    component: QRHome,
  },
  {
    path: "/profile/:item?",
    name: "Profile",
    component: Profile,
    props: true,
  },
  {
    path: "/profile/edit/:item?",
    name: "EditProfile",
    component: UpdateProfile,
    props: true,
  },
  {
    path: "/profile/nguponyuk/:item?",
    name: "NguponYuk",
    component: NguponYuk,
    props: true,
  },
  {
    path: "/profile/nguponyuk/history/:item?",
    name: "NguponYukHistory",
    component: NguponYukHistory,
    props: true,
  },
  {
    path: "/profile/referral-nguponyuk/:item?",
    name: "ReferralNguponYuk",
    component: ReferralNguponYuk,
    props: true,
  },
  {
    path: "/profile/user-referral-nguponyuk/:item?",
    name: "UserReferralNguponYuk",
    component: UserReferralNguponYuk,
    props: true,
  },
  {
    path: "/nguponyuk-cart/:from",
    name: "NguponYukCart",
    component: NguponYukCart,
    props: true,
  },
  {
    path: "/nguponyuk-outlet/:page",
    name: "NguponYukOutletList",
    component: NguponYukOutletList,
    props: true,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/history/:id/:from",
    name: "HistoryDetail",
    component: HistoryDetail,
  },
  {
    path: "/chat/:id",
    name: "Chat",
    component: Chat,
  },
];

// resto owner route
const routesOwner = [
  {
    path: "/owner/resto-create",
    name: "OwnerRestoCreate",
    component: OwnerRestoCreate,
  },
  {
    path: "/owner/resto-edit",
    name: "OwnerRestoEdit",
    component: OwnerRestoEdit,
  },
  {
    path: "/owner/referral",
    name: "OwnerReferral",
    component: OwnerReferral,
  },
  {
    path: "/owner/verifikasi",
    name: "OwnerVerifikasi",
    component: OwnerVerifikasi,
  },
  {
    path: "/owner/home",
    name: "OwnerHome",
    component: OwnerHome,
  },
  {
    path: "/owner/product",
    name: "OwnerProduct",
    component: OwnerProduct,
  },
  {
    path: "/owner/product/:id?/choice/:item?",
    name: "ProductChoice",
    component: Choice,
  },
  {
    path: "/owner/product/choice-list/:item?",
    name: "ListChoice",
    component: Index,
  },
  {
    path: "/owner/productform/:item?",
    name: "OwnerProductForm",
    component: OwnerProductForm,
  },
  {
    path: "/owner/subcategories",
    name: "OwnerSubCategories",
    component: OwnerSubCategories,
  },
  {
    path: "/owner/promo",
    name: "OwnerPromo",
    component: OwnerPromo,
  },
  {
    path: "/owner/promoform/:promo?",
    name: "OwnerPromoForm",
    component: OwnerPromoForm,
  },
  {
    path: "/owner/follower",
    name: "OwnerFollower",
    component: OwnerFollower,
  },
  // lainnya
  {
    path: "/owner/transaction",
    name: "OwnerTransaction",
    component: OwnerTransaction,
  },
  {
    path: "/owner/transaction/:id",
    name: "OwnerDetailTransaction",
    component: OwnerDetailTransaction,
  },
  {
    path: "/owner/employees",
    name: "OwnerUser",
    component: OwnerUser,
  },
  {
    path: "/owner/schedule",
    name: "OwnerSchedule",
    component: OwnerSchedule,
  },
  {
    path: "/owner/subscription",
    name: "OwnerSubscription",
    component: OwnerSubscription,
  },
  {
    path: "/owner/history",
    name: "OwnerHistory",
    component: OwnerHistory,
  },
  {
    path: "/owner/owners",
    name: "OwnerAddOwner",
    component: OwnerOwners,
  },
  {
    path: "/owner/notification",
    name: "TestNotification",
    component: TestNotification,
  },
  {
    path: "/owner/income",
    name: "OwnerIncome",
    component: OwnerIncome,
  },
  {
    path: "/owner/resto",
    name: "OwnerRestoDetail",
    component: OwnerRestoDetail,
  },
  {
    path: "/owner/chat/:id",
    name: "OwnerChat",
    component: OwnerChat,
  },
  {
    path: "/owner/table/:item?",
    name: "OwnerTable",
    component: OwnerTable,
  },
  {
    path: "/owner/voucher",
    name: "OwnerVoucher",
    component: OwnerVoucher,
  },
  {
    path: "/intro",
    name: "IntroductionComponent",
    component: Introductions,
  },
];

routes = routes.concat(routesOwner);
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { top: 0 };
    }
  },
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
