import { createApp } from "vue";
import App from "./App.vue";
import Toaster from "@meforma/vue-toaster";
import router from "./router";
import store from "./store";
import helper from "./helpers";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueDatePicker from "@vuepic/vue-datepicker";
import StickyComponent from "./components/StickyComponent.vue";
import StickyModalComponent from "./components/StickyModalComponent.vue";
import GAuth from "vue3-google-oauth2";

import "@vuepic/vue-datepicker/dist/main.css";

import { FontAwesomeIcon } from "./plugins/font-awesome";

import "./assets/style/stisla.css";
import "./assets/style/custom.css";
import "./assets/introjs/introjs.css";

//style for upload file
import "@/assets/style/fileupload.css";

import { DEV_URL, PROD_URL } from "./utils/url.js";

const app = createApp(App);

app.config.productionTip = true;
app.config.globalProperties.mainUrl = app.config.productionTip
  ? PROD_URL
  : DEV_URL;

import $ from "jquery";
window.$ = window.jQuery = $;

const gauthOption = {
  clientId:
    "290649508281-mvmjki0n6hg0vltc2mknu8blulhrmnnp.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};

app.use(GAuth, gauthOption);
app.use(Toaster);
app.use(router);
app.use(store);
app.use(helper);
app.use(VueSweetalert2);
app.component("StickyComponent", StickyComponent);
app.component("StickyModalComponent", StickyModalComponent);
app.component("VueDatePicker", VueDatePicker);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
