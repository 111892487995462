import axios from "axios"
export default {
    namespaced: true,
    state: {
        listVoucher: [],
        outlet_id: null,
    },
    mutations: {
        setListVoucher(state, listVoucher) {
            // listVoucher.isSelected = false
            state.listVoucher = listVoucher
        },
        setOutletId(state, outlet_id) {
            state.outlet_id = outlet_id
        }
    },
    actions: {
        fetch({ commit }, url) {
            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then(response => {
                    let splitted = url.split('?outlet_id=')
                    commit('setOutletId', splitted[splitted.length - 1])
                    commit('setListVoucher', response.data);
                })
                .catch((error) => {
                    throw new Error(error.response.status)
                });
        },
        set: ({ commit, state }, id) => {
            let _voucher = state.listVoucher.find(element => element.id == id)
            _voucher.isSelected = !(_voucher.isSelected)
            commit('setListVoucher', [
                ...state.listVoucher.filter(element => element.id !== id),
                _voucher
            ])
        },
        claim: async ({ state }, url) => {
            let _response = {}
            let formData = new FormData
            let selectedVoucher = state.listVoucher.filter(item => item.isSelected)
            if (selectedVoucher.length > 0) {
                selectedVoucher.map((item) => formData.append('voucher[]', item.id))
                await axios.post(url, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                    .then(response => {
                        _response = response
                    })
                    .catch((error) => {
                        console.log(error)
                    });
                return _response
            }
        }
    },
    getters: {
        getSelectedVoucher: function (state) {
            return state.listVoucher.map(voucher => voucher.id)
        },
        displayAllVoucher: function (state) {
            let ret = [];
            state.listVoucher.forEach((item) => {
                if (item != undefined) {
                    let _return = {
                        str: []
                    }
                    _return = { ...item }
                    _return.str = []
                    if (item.min_order > 0) {
                        _return.str.push(`Min. transaksi Rp. ${Math.ceil(item.min_order / 1000)}rb`)
                    }
                    if (item.max_amount > 0) {
                        _return.str.push(`Maks Rp. ${Math.ceil(item.max_amount / 1000)}rb`)
                    }

                    ret.push(_return)
                }
            });
            return ret;
        },
        countAllVoucher: (state) => {
            return state.listVoucher.length
        },
        countSelectedVoucher: (state) => {
            return state.listVoucher.filter(item => item.isSelected).length
        },
        sumPriceReduction: (state) => (price) => {
            let selectedVoucher = state.listVoucher.filter(item => item.isSelected)
            let _priceReduction = 0;
            selectedVoucher.map((voucher) => {
                let reduction = 0
                if (price > voucher.min_order) {
                    if (voucher.discount != 0) {
                        reduction = price * (voucher.discount / 100)
                    } else {
                        reduction = voucher.potongan
                    }
                }
                _priceReduction += (reduction > voucher.max_amount) ? voucher.max_amount : reduction
            })
            return _priceReduction
        }
    }
};