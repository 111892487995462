<template>
  <div class="body">
    <div class="main-wrapper">
      <div class="main-content">
        <div class="container container-welcome text-center">
          <img class="mx-auto mb-5" src="@/assets/irgLogo.png" alt="" />
          <h1 class="text-dark font-weight-bold">Indonesia Resto Guide</h1>
          <p class="text-dark mb-4 px-3">
            Your Guidance to find the perfect Restaurant in Indonesia
          </p>
          <a
            href="/resto"
            id="btn-welcome"
            class="btn btn-irg btn-danger bg-irg btn-lg btn-rounded w-100"
          >
            Mulai sekarang!
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeComponent",
  data(){
    return {
      deviceid : localStorage.getItem('device_id')
    }
  },
  props: {
    //
  },
  methods:{
    
  },
  mounted() {
    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === 'function';
    };

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };
    
    if (
      localStorage.getItem("user") !== null &&
      localStorage.getItem("user") !== ""
    ) {
      this.$router.push("/resto");
    }
    // console.log(this.deviceid)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
  font-weight: 500;
}

.btn-irg {
  color: white !important;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  padding: 0.6rem 1.5rem;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh; /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  max-height: 30vh;
}

.main-content {
  margin-top: -30px;
}
</style>
