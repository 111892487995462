<template>
  <div class="body" style="margin-bottom: 7vh">
    <div v-if="isLoading" class="loading-container">
      <img src="@/assets/loading.gif" alt="Loading" class="loading-icon" />
    </div>
    <section class="section">
      <div class="container">
        <div class="d-flex w-100 mt-4 mb-3 px-2">
          <a @click="goBack" class="text-decoration-none text-weight-bold text-dark text-1rem">
            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" class="icon-xl mr-3"></font-awesome-icon>
            {{
      this.$route?.query?.is_choice?.toString() == "true"
        ? "Pilihan"
        : "Add-On / Tambahan"
    }}
          </a>
        </div>
        <ul class="col-12 nav nav-tabs text-irg mb-2" id="myTab" role="tablist">
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active pt-0">
            <div class="container">
              <div v-if="this.$route?.query?.is_choice?.toString() == 'true'">
                <div class="container mt-3" v-for="(items, itemIndex) in categoryChoiceData" :key="itemIndex">
                  <div class="border rounded-10 align-items-center pt-2">
                    <div class="d-flex justify-content-between px-3 align-items-center">
                      <div class="text-90rem py-2 text-dark">
                      {{ items.name }}
                    </div>
                    <div class="text-right">
                      <button class="btn btn-danger btn-icon py-0 rounded-20 mr-2"
                        @click="deleteCategory(items.id)">
                        Hapus
                      </button>
                      <button class="btn btn-info btn-icon py-0 rounded-20"
                        @click="showModal(items, [], items.name, '', null, null)">
                        Tambah
                      </button>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-12 px-4 py-2">
                      <div class="px-1 py-1" v-for="(item, itemIndex) in items.items" :key="itemIndex">
                        <div class="row border-top justify-content-center align-items-center"
                          @click="showModal(items, item, items.name, item.name, item.price, itemIndex)">
                          <div class="col-7 text-90rem py-1">
                            {{ itemIndex === 0 ? item.name + ' (Netral)' : item.name }}
                          </div>
                          <div class="col-3 pr-3 text-right">
                            +{{ item.price }}
                          </div>
                          <div class="col-1">
                            <font-awesome-icon icon="fa-solid fa-angle-right"></font-awesome-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="col-12 btn btn-irg rounded-20" @click="showModal([], [], '', '', 0, null)">
                    <div class="text-90rem py-1 text-center">
                      <font-awesome-icon icon="fa-solid fa-plus" class="mr-2"></font-awesome-icon>
                      {{ "Tambah Kategori" }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!items.length > 0 &&
      !isLoading &&
      this.$route?.query?.is_choice?.toString() !== 'true'
      " class="empty-data center-screen text-center">
                <div class="empty-data-content">
                  <h5 class="text-irg">Datanya Masih Kosong Nih!</h5>
                  <p>Silahkan jelajahi fitur lainnya</p>
                </div>
              </div>
              <div v-if="items.length > 0 &&
      !isLoading &&
      this.$route?.query?.is_choice?.toString() !== 'true'
      " class="row">
                <div class="col-md-12 py-3">
                  <div class="input-group">
                    <input v-model="searchItem" type="text" class="form-control search-input" :placeholder="index == 0 ? 'Cari Pilihan' : 'Cari Add-On / Tambahan'
      " />
                    <div class="input-group-append">
                      <button class="btn btn-light" type="button">
                        <font-awesome-icon :icon="['fas', 'magnifying-glass']"></font-awesome-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-card-menu" v-for="(item, itemIndex) in doFilter" :key="itemIndex">
                  <div class="card rounded-10 shadow-primary" :class="{ 'onclick-border': item.is_selected }">
                    <div class="card-body p-1" @click="addItem(item.id, item.is_selected)">
                      <div :class="{ row: index == 0 }">
                        <div class="col-5" v-if="index == 0">
                          <img class="img-responsive pt-2 w-100 h-100 rounded-20 img-menu" :src="item.img" alt=""
                            :onerror="defaultFood" />
                        </div>
                        <div :class="index == 0
      ? 'col-7 p-0 py-2'
      : 'col-12 p-0 py-2 px-3'
      ">
                          <div class="mw-60">
                            <p class="text-80rem font-weight-normal mb-0">
                              {{ item.category }}
                            </p>
                            <h6 class="text-dark text-weight-800 mb-0">
                              {{ item.name }}
                            </h6>
                          </div>
                          <p class="text-dark text-75rem font-weight-normal text-ellipsis w-80 mb-0">
                            {{ item.description }}
                          </p>
                          <p class="mb-0 text-irg text-80rem">
                            {{ item.is_selected ? "Dipakai" : "" }}
                          </p>
                          <p class="text-dark text-75rem mb-0">
                            Rp. {{ $numFormat(item.price) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <template v-if="categoryModal">
    <div class="modal fade show d-block" ref="categoryModal" tabindex="-1" role="dialog" id="categoryModal">
      <div class="vertical-alignment-helper" style="padding: 20px">
        <div class="modal-dialog vertical-align-center" role="document">
          <div class="modal-content categoryname-modal">
            <div class="modal-header">
              <h5 class="modal-title">Isi Data Kategori</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="modal-close"
                @click="categoryModal = false, categoryName = '', choiceName = '', choicePrice = null, isNewDatas = false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body px-0 pt-2 pb-0">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <p class="p-0 m-0 px-2 text-1rem">
                      Nama Kategori
                    </p>
                    <div class="col-12 p-0 py-1 px-2">
                      <input class="form-control w-100" type="text" id="categoryname" name="categoryname"
                        v-model="categoryName" placeholder="Nama Kategorimu"
                        :readonly="isNewDatas ? this.categoryData?.length == 0 ? false : true : true" />
                    </div>
                  </div>
                </div>
                <div class="row pt-2">
                  <div class="col-12">
                    <p class="p-0 m-0 px-2 text-1rem">
                      {{ choiceIndex === 0 ? 'Nama Pilihan Utama (Netral)' : 'Nama Pilihan' }}
                    </p>
                    <div class="col-12 p-0 pb-1 px-2">
                      <input class="form-control w-100" type="text" id="categoryname" name="categoryname"
                        v-model="choiceName" placeholder="Nama Pilihan" />
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="p-0 m-0 px-2 text-1rem">
                      {{ choiceIndex === 0 ? 'Harga Pilihan Utama (Netral)' : 'Harga Pilihan' }}
                    </p>
                    <div class="col-12 p-0 pb-1 px-2">
                      <input class="form-control w-100" type="text" id="categoryname" name="categoryname"
                        v-model="choicePrice" placeholder="Harga Pilihan" :readonly="isNewDatas ? true : false" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="categoryModal = false, categoryName = '', choiceName = '', choicePrice = null, isNewDatas = false">
                Batal
              </button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" v-if="this.choiceData?.length !== 0 && this.choiceIndex !== 0"
                @click="deleteData(this.choiceData?.id)">
                Hapus
              </button>
              <button type="button" class="btn btn-irg text-white" v-if="this.choiceData?.length !== 0 && this.choiceIndex !== 0" @click="handleEdit">
                Simpan
              </button>
              <button type="button" class="btn btn-irg text-white" v-else @click="handleAdd">
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </template>
</template>

<script>
import axios from "axios";
export default {
  name: "ProductComponent",
  data() {
    return {
      isLoading: false,
      items: [],
      index: 0,
      searchItem: "",
      categoryModal: false,
      categoryData: [],
      categoryName: "",
      choiceData: [],
      choiceIndex: 0,
      choiceName: "",
      choicePrice: null,
      isNewDatas: false,
      categoryChoiceData: []
    };
  },
  computed: {
    doFilter() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.searchItem.toLowerCase());
      });
    },
  },
  mounted() {
    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === "function";
    };

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };

    if (this.$route?.query?.is_choice !== undefined) {
      if (this.$route?.query?.is_choice?.toString() == "true") {
        const currentQuery = { ...this.$route.query };
        currentQuery.is_choice = "true";
        this.$router.replace({ query: currentQuery });
        this.moveTab(0);
      } else {
        const currentQuery = { ...this.$route.query };
        currentQuery.is_choice = "false";
        this.$router.replace({ query: currentQuery });
        this.moveTab(1);
      }
    } else {
      this.moveTab(1);
    }
  },
  created() {
    if (localStorage.getItem("token") === null) {
      this.$router.push("/login");
    }
    window.addEventListener("popstate", this.goBack);
  },
  methods: {
    showModal(category, choice, categoryName, choiceName, choicePrice, index) {
      if (category !== null) {
        this.categoryName = category.name;
        this.categoryData = category;
        this.choiceData = choice;
        this.choiceIndex = index
      } else {
        this.categoryName = categoryName; 
        this.categoryData = [];
        this.choiceData = [];
        this.choiceIndex = 0;
      }
      this.choiceName = choiceName;
      this.choicePrice = choicePrice;
      if ((this.choicePrice === 0 && (category?.name?.length === 0 || category?.name === undefined)) || index === 0) this.isNewDatas = true;
      else this.isNewDatas = false;
      this.categoryModal = true;
    },
    handleAdd() {
      if (this.categoryName.length === 0) {
        this.$toast.show("Nama kategori harus diisi!", { position: "bottom" });
        setTimeout(this.$toast.clear, 2000);
      } else if (this.choiceName.length === 0) {
        this.$toast.show("Nama pilihan harus diisi!", { position: "bottom" });
        setTimeout(this.$toast.clear, 2000);
      } else if (this.choicePrice.length === 0) {
        this.$toast.show("Harga pilihan harus diisi!", { position: "bottom" });
        setTimeout(this.$toast.clear, 2000);
      } else {
        const token = localStorage.getItem("token");

        let formData = {
          "name": this.choiceName,
          "is_choice": true,
          "price": this.choicePrice,
          "description": '-',
          "outlet_id": JSON.parse(localStorage.getItem("outlet")).id,
          "category_id": this.categoryData?.length != 0 ? this.categoryData.id : this.categoryName,
        }

        let _url = this.mainUrl + "/api/irg/v2/owner/item/" + this.$route.params.item + "/choice/store";

        axios.post(_url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        }).then((res) => {
          // console.log(res)
          // this.handleRequest(res);
          this.categoryModal = false;
          this.categoryName = '';
          this.choiceName = '';
          this.choicePrice = null;
          this.isNewDatas = false;
          window.location.reload();
        }).catch((err) => {
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
          console.log(err);
        });
      }
    },
    handleEdit() {
      if (this.categoryName.length === 0) {
        this.$toast.show("Nama kategori harus diisi!", { position: "bottom" });
        setTimeout(this.$toast.clear, 2000);
      } else if (this.choiceName.length === 0) {
        this.$toast.show("Nama pilihan harus diisi!", { position: "bottom" });
        setTimeout(this.$toast.clear, 2000);
      } else if (this.choicePrice.length === 0) {
        this.$toast.show("Harga pilihan harus diisi!", { position: "bottom" });
        setTimeout(this.$toast.clear, 2000);
      } else {
        const token = localStorage.getItem("token");

        let formData = {
          "name": this.choiceName,
          "is_choice": true,
          "price": this.choicePrice,
          "description": '-',
          "outlet_id": JSON.parse(localStorage.getItem("outlet")).id,
          "category_id": this.categoryData?.length != 0 ? this.categoryData.id : this.categoryName,
          "_method": "PUT"
        }

        // formData.append("_method", "PUT");

        let _url = this.mainUrl + "/api/irg/v2/owner/item/" + this.$route.params.item + "/choice/" + this.choiceData?.id + "/update";

        axios.post(_url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        }).then((res) => {
          // console.log(res)
          // this.handleRequest(res);
          this.categoryModal = false;
          this.categoryName = '';
          this.choiceName = '';
          this.choicePrice = null;
          this.isNewDatas = false;
          window.location.reload();
        }).catch((err) => {
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
          console.log(err);
        });
      }
    },
    handleRequest(res) {
      if (this.$route?.query?.is_choice?.toString() == "true") {
        res?.data?.data?.data?.forEach((resData) => {
          this.categoryChoiceData.push(resData);
        });
      } else {
        res?.data?.data?.data?.forEach((resData) => {
          this.items.push(resData);
        });
      }
      this.nextPage = res?.data?.data?.next_page_url;
      this.isLoading = false;
    },
    addPage(id) {
      const query = this.index == 0 ? "?is_choice=pilihan" : "?is_choice=addon";
      this.$router.push("/owner/product/" + id + "/choice" + query);
    },
    defaultFood(event) {
      event.target.src = require("@/assets/food-default.jpg");
    },
    moveTab(tab) {
      const updatedQuery = { ...this.$route.query };
      updatedQuery.is_choice = tab == 0 ? "true" : "false";
      this.$router.replace({ query: updatedQuery });

      this.index = tab;
      this.isLoading = true;

      let _url =
        this.mainUrl +
        "/api/irg/v2/owner/item/" +
        this.$route.params.item +
        "/choice";

      const token = localStorage.getItem("token");
      axios
        .get(_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            is_choice: tab == 0 ? true : false,
            id: JSON.parse(localStorage.getItem("outlet")).id,
            per_page: 0,
          },
        })
        .then((res) => {
          // this.items = res.data.data.data;
          // console.log(res)
          this.handleRequest(res)
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    handleScroll() {
      const scrollableHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrolledDistance = window.scrollY;
      const scrollThreshold = 100;

      if (scrollableHeight - scrolledDistance < scrollThreshold) {
        this.isLoading = true;
        let _url = this.nextPage;
        this.nextPage = null;
        const token = localStorage.getItem("token");
        axios
          .get(_url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              is_choice: this.index == 0 ? true : false,
              id: JSON.parse(localStorage.getItem("outlet")).id,
              per_page: 0,
            },
          })
          .then(this.handleRequest)
          .catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
              this.$router.push("/login");
            }
          });
      }
    },
    addItem(id, is_selected) {
      this.isLoading = true;

      let formData = {
        is_choice: this.index == 0 ? true : false,
        is_selected: !is_selected,
      };

      const token = localStorage.getItem("token");
      let _url =
        this.mainUrl +
        "/api/irg/v2/owner/item/" +
        this.$route.params.item +
        "/choice/" +
        id +
        "/sync";

      axios
        .put(_url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          // console.log(err);
          if (err.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    deleteCategory(id) {
      this.$swal({
        title: "Hapus Kategori",
        text: "Apakah anda yakin ingin menghapus Kategori ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Batal",
        cancelButtonText: "Iya",
        customClass: {
          cancelButton: "btn btn-success",
          confirmButton: "btn btn-danger mr-3",
        },
        buttonsStyling: false,
      }).then((res) => {
        if (!res.isConfirmed) {
          const token = localStorage.getItem("token");
          axios
            .delete(
              this.mainUrl +
              "/api/irg/v2/owner/item/" +
              this.$route.params.item +
              "/choice/category/" +
              id +
              "/delete",
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              // console.log(res);
              if (res.status) {
                window.location.reload();
              }
            })
            .catch((err) => {
              // console.log(err.response.data.message);
              if (err.response.status == 401) {
                this.$router.push("/login");
              }
            });
        }
      });
    },
    deleteData(id) {
      this.categoryModal = false;
      let type = this.index == 0 ? "Pilihan" : "Add On";
      this.$swal({
        title: "Hapus " + type,
        text: "Apakah anda yakin ingin menghapus " + type + " ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Batal",
        cancelButtonText: "Iya",
        customClass: {
          cancelButton: "btn btn-success",
          confirmButton: "btn btn-danger mr-3",
        },
        buttonsStyling: false,
      }).then((res) => {
        if (!res.isConfirmed) {
          const token = localStorage.getItem("token");
          axios
            .delete(
              this.mainUrl +
              "/api/irg/v2/owner/item/" +
              this.$route.params.item +
              "/choice/" +
              id +
              "/delete",
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
                params: {
                  is_choice: this.index == 0 ? true : false,
                },
              }
            )
            .then((res) => {
              // console.log(res);
              if (res.status) {
                window.location.reload();
              }
            })
            .catch((err) => {
              // console.log(err.response.data.message);
              if (err.response.status == 401) {
                this.$router.push("/login");
              }
            });
        }
      });
    },
    edit(id) {
      let is_choice =
        this.index == 0 ? "?is_choice=pilihan" : "?is_choice=addon";
      this.$router.push(
        "/owner/product/" +
        this.$route.params.item +
        "/choice/" +
        id +
        is_choice
      );
    },
    goBack() {
      this.$router.replace("../../productform/" + this.$route.params.item);
    },
  },
};
</script>

<style scoped>
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Your scoped styles go here */
.card-action {
  position: absolute;
  top: 10px;
  right: 25px;
  width: 100%;
  height: 50px;
}

.mw-60 {
  max-width: 60%;
}

.action-bar {
  position: fixed;
  padding-bottom: 10px;
  bottom: 0;
  /* background-color: white; */
  z-index: 999;
}

.btn-add {
  height: 55px;
  width: 55px;
}

.card {
  margin-bottom: 10px;
}

.img-menu {
  max-height: 110px;
  object-fit: cover;
  object-position: center center;
}

.onclick-border {
  outline: 1px solid #5b3825;
  outline-offset: -1px;
}
</style>
